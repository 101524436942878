import { loginCertiConfirm, loginCertiEmail } from '@apis/sign';
import BACKGROUND from '@assets/images/background.jpg';
import { EnumRouteUrl } from '@constants/ConstRoute';
import CommonContainer from '@pages/components/common/atoms/CommonContainer';
import HorizontalBlank from '@pages/components/common/atoms/HorizontalBlank';
import NumericInput from '@pages/components/common/atoms/NumericInput';
import EmailTermsModal from '@pages/components/common/molecules/EmailTermsModal';
import JoinModal from '@pages/components/common/molecules/JoinModal';
import TermsModal from '@pages/components/common/molecules/TermsModal';
import { Button, Form, Input, Space, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import sha256 from 'crypto-js/sha256';
import { useEffect, useState } from 'react';
import useCountDown from 'react-countdown-hook';
import { useNavigate } from 'react-router-dom';
import { authApi, axiosInstance } from 'src/axios/useRequest';
import styled from 'styled-components';
import { useAuth } from '../../utils/auth';

const LoginPage = () => {
  const [responseMessage, setResponseMessage] = useState('');
  const [termsVisible, isTermsVisible] = useState(false);
  const [emailTermsVisible, isEmailTermsVisible] = useState(false);
  const [joinVisible, isJoinVisible] = useState(false);
  const { authAction, loading } = useAuth();
  const navigate = useNavigate();
  const [certi, isCerti] = useState<boolean>(false);
  const [certiConfirm, isCertiConfirm] = useState<boolean>(false);
  const [twofactYn, setTwofactYn] = useState<YN>('N');
  const [logInYn, setLogInYn] = useState<YN>('N');
  const [emailYn, setEmailYn] = useState<YN>('N');
  const [v2privacyAgreeYn, setV2privacyAgreeYn] = useState<YN>('N');
  const initialTime = 1000 * 60 * 3;
  const interval = 1000;
  const [timeLeft] = useCountDown(initialTime, interval);
  const [userEmail, setUserEmail] = useState<string>();
  const [pchgYn, setPchgYn] = useState<YN>('N');

  const [form] = useForm();
  const [passwordform] = useForm();
  // const [password, setPassword] = useState('');
  const [originPassword, setOriginPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [cid, setCid] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await authApi.post('/comm/control/auth/login');
      } catch (e: any) {
        const status = e?.response?.status
        if (status === 401) {
          const message = e?.response?.data?.message;
          setResponseMessage(message || '시스템에러. 관리자에게 문의하세요.');
        } else {
          setResponseMessage(e?.response || '시스템에러. 관리자에게 문의하세요.');
        }
      }
    };

    fetchData();
  }, []); // Only run once during initialization

  const submitHandle = async (submitData: any) => {
    setResponseMessage('');
    try {
      const res = await authAction.login(submitData);
      setTwofactYn(res?.twofactYn);
      setV2privacyAgreeYn(res?.v2privacyAgree);
      setEmailYn(res?.emailYn);
      if (res?.email) {
        setUserEmail(res?.email);
      }
      if (res?.twofactYn !== 'Y') {
        navigate(EnumRouteUrl.HOME);
      }
      if (res?.v2privacyAgree !== 'Y') {
        isEmailTermsVisible(true);
      }
    } catch (e: any) {
      const status = e?.response?.status;
      if (status === 401) {
        const message = e?.response?.data?.message;
        if (message.includes('password')) {
          setResponseMessage(message);
          setPchgYn('Y');
        } else {
          setResponseMessage(message || '시스템에러. 관리자에게 문의하세요.'); 
        }
      } else {
        setResponseMessage(e?.response?.statusText || '시스템에러. 관리자에게 문의하세요.');
      }
    }
  };

  const pwChangeSubmitHandle = async () => {
      try {
        const hashedNewPassword = sha256(newPassword).toString();
        const hashedOrgPassword = sha256(originPassword).toString();
        
        const res = await axiosInstance.post('/comm/control/auth/changeExpiredPwd', {
          userId: cid,
          password: hashedNewPassword,
          orgPassword: hashedOrgPassword,
        });
        
        if (res?.status === 200) {
          message.success('비밀번호를 변경하였습니다. 다시 로그인하세요.');
          setPchgYn('N');
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      } catch (e: any) {
        const status = e?.response?.status;
  
        if (status === 401) {
          const errorMessage = e?.response?.data?.message;
          message.error(errorMessage || '비밀번호 변경에 실패하였습니다.');
        } else {
          message.error(e?.response?.statusText || '비밀번호 변경에 실패하였습니다.');
        }
      }
    };

  const emailCertiHandle = async () => {
    setLogInYn('Y');
    const param = await form.validateFields(['email', 'userId']);
    if (emailYn==='Y' && param.email !== userEmail) { 
      message.error(
       '등록하신 email정보가 일치하지 않습니다.',
      );
      return false;
    };
    
    try {
      await loginCertiEmail({
        ...param,
        cid: param.userId,
        email: param.email,
      });
      isCerti(true);
    } catch (e: any) {
      const errorResponse = e?.response;
      message.error(
        errorResponse?.data?.message
          ? errorResponse?.data?.message
          : errorResponse?.statusText
          ? errorResponse?.statusText
          : '시스템에러. 관리자에게 문의하세요.',
      );
    }
  };
  const emailCertiConfirmHandle = async () => {  // MFA인증 확인
    const param = await form.validateFields(['prefix', 'email', 'userId', 'pinNumber', 'password']);
    // param.password;
    const hashed = sha256(param.password).toString();
    try {
      const res = await loginCertiConfirm({
        ...param,
        password: hashed,
        localApp: 'embassy',
        cid: param.userId,
        email: param.email ? `${param.prefix ?? ''}${param.email}` : '',
      });
      isCertiConfirm(true);

      authAction.setToken(res.data);
    } catch (e: any) {
      const errorResponse = e?.response;
      const message = errorResponse?.data?.message;
      
      if (message.includes('password')) {
        setResponseMessage(message);
        setPchgYn('Y');
      } else {
        message.error(
          errorResponse?.data?.message
            ? errorResponse?.data?.message
            : errorResponse?.statusText
              ? errorResponse?.statusText
              : '시스템에러. 관리자에게 문의하세요.',
        );
      }
    }
  };
  return (
    <CommonContainer
      style={{ background: `no-repeat 100% url(${BACKGROUND})`, backgroundPosition: 'center left', backgroundSize: 'cover' }}>
      <Contents>
        <Form
          form={form}
          name='basic'
          style={{ width: '100%' }}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true
          }}
          onFinish={submitHandle}>
          <HorizontalBlank height={30} />
          <Form.Item
            label='ID'
            name='userId'
            rules={[
              {
                required: true,
                message: 'Please input your id',
              },
            ]}>
            <Input value={cid} disabled={twofactYn === 'Y'} onChange={({ target }) => setCid(target.value)}  />
          </Form.Item>

          <HorizontalBlank height={20} />
          <Form.Item
            label='Password'
            name='password'
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
            ]}>
            <Input.Password value={originPassword} disabled={twofactYn === 'Y'} onChange={({ target }) => setOriginPassword(target.value)} />
          </Form.Item>
          {
            twofactYn === 'Y' &&
            (
              <Form.Item
                label='이메일'
                name='email'
                rules={[
                  {
                    required: true,
                    message: '이메일을 입력해주세요.',
                  },
                ]}>
                <Input.Search
                  style={{
                    width: '100%',
                  }}
                  enterButton={'이메일인증'}
                  //suffix={milisecondToFormat(timeLeft)}
                  onSearch={emailCertiHandle}
                  // addonAfter={<Button>번호인증</Button>}
                />
              </Form.Item>
            )}
           {certi && (
            <Form.Item
              label='인증번호'
              name='pinNumber'
              rules={[
                {
                  required: true,
                  message: '인증번호를 입력해주세요.',
                },
                {
                  len: 6,
                  message: '6자리 숫자를 입력해주세요.',
                },
              ]}>
              <NumericInput isSearch disabled={certiConfirm} enterButton={'Login'} onSearch={emailCertiConfirmHandle} />
            </Form.Item>
          )}
          {responseMessage && (
            <Form.Item
              wrapperCol={{
                offset: 6,
                span: 17,
              }}>
              <ResponseMessageFont>{responseMessage}</ResponseMessageFont>
            </Form.Item>
          )}
          <HorizontalBlank height={20} />
          <Form.Item
            wrapperCol={{
              offset: -1,
              span: 16,
            }}
            style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <Space>
            {logInYn !== 'Y' &&(
              <Button type='primary' htmlType='submit' loading={loading}>
                Login
              </Button>
              )}
            {logInYn !== 'Y' &&(
              <Button onClick={() => isTermsVisible(true)}>회원가입</Button>
            )}
            </Space>
          </Form.Item>
        </Form>

        <Form
          form={passwordform}
          name='pchg'
          style={{ width: '100%' }}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={pwChangeSubmitHandle}>
        {pchgYn === 'Y' &&
            (
            <>
              <Form.Item
            label='New Password'
            name='newpassword'
            rules={[
                  {
                    required: true,
                    message: 'Please input your new password',
                  },
                  {
                    validator: (_, value) => {
                      const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;
      
                      if (reg.test(value) || !value) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject(
                          new Error('비밀번호는 최소 10자이상, 문자와 숫자와 특수문자를 최소 1자 이상 입력해주세요.'),
                        );
                      }
                    },
                  },
                ]}>
                <Input.Password />
              </Form.Item>
              <Form.Item
              label='Confirm Password'
              name='confirmpassword'
              rules={[
                    {
                      required: true,
                      message: 'Confirm your password',
                    },
                    {
                      validator: (_, value) => {
                        const reg = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{10,}$/;
        
                        if (reg.test(value) || !value) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(
                            new Error('비밀번호는 최소 10자이상, 문자와 숫자와 특수문자를 최소 1자 이상 입력해주세요.'),
                          );
                        }
                      },
                    },
                    {
                      validator: (_, value) => {
                        const password = passwordform.getFieldValue('newpassword');
                        if (value === password) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error('비밀번호가 일치하지 않습니다. 재입력하시기 바랍니다.'));
                        }
                      },
                    },
                  ]}>
                  <Input.Password value={newPassword} onChange={({ target }) => setNewPassword(target.value)}/>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 6,
                  span: 15,
                }}
                style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <Space>
                  <Button type='dashed' htmlType='submit' loading={loading}>Change password</Button>
                </Space>
              </Form.Item>
            </>
          )}

        </Form>
      </Contents>
      

      <EmailTermsModal
        visible={emailTermsVisible}
        onClose={() => {
          isEmailTermsVisible(false)
        }}
        onOk={() => {
          isEmailTermsVisible(false);  // 모달 닫기
          setLogInYn('Y');
         }}
      />
      
      <TermsModal
        visible={termsVisible}
        onClose={() => isTermsVisible(false)}
        onOk={() => {
          isTermsVisible(false);
          isJoinVisible(true);
        }}
      />
      <JoinModal visible={joinVisible} onClose={() => isJoinVisible(false)} />
    </CommonContainer>
  );
};

const Contents = styled.div`
  width: 500px;
  border-top: 6px solid #d40511;
  box-shadow: 8px 6px 23px 11px rgba(0, 0, 0, 0.2);
  background: white;
`;

const ResponseMessageFont = styled.div`
  color: #ff4d4f;
`;

export default LoginPage;
